@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    overflow: hidden;
    height: fit-content;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:45px 0 108px;

    @include breakpoint(d) {
        padding: 90px 54px 156px 56px;
    }

    @include breakpoint(m) {
        padding: 45px 27px 78px 28px;
    }
}

.title_box {
    @include inter-bold;
    font-size: 36px;
    color: #14284E;

    @include breakpoint(d) {
        font-size: 64px;
    }

    @include breakpoint(m) {
        font-size: 32px;
    }
}

.screenshot_box {
    border-radius: 15px;
    margin: 68px 0 0;
    height: 629px;
    width: 1120px !important;
    overflow: hidden;

    @include breakpoint(d) {
        margin: 72px 0 0;
        height: fit-content;
        width: 90% !important;
    }

    @include breakpoint(m) {
        margin: 36px 0 0;
    }
}

.screenshot {
    object-fit: cover;
    height: 629px;
    width: 1120px;

    @include breakpoint(d) {
        width: 100%;
        height: auto;
        aspect-ratio: 320/180;
    }
}