@import './variables.scss';
@import './mixins.scss';


.hero,
.h1,
.h2,
.h3,
.h4 {
    @include poppins;
    font-weight: 700;
}

.hero {
    font-size: 96px;
    line-height: 1;
    letter-spacing: -.02em;
}

.h1 {
    font-size: 64px;
    line-height: 1;
    letter-spacing: -.02em;
}

.h2 {
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: -.02em;
}

.h3 {
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -.01em;
}

.h4 {
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: -.01em;
}

.title {
    @include title;
}