@import "../../../styles/mixins.scss";

.up_arrow {
    position: fixed;
    bottom: -100px;
    right: 10px;
    z-index: 1;

    transition: all 0.3s ease-in-out;

    &.showing {
        bottom: 19px;
    }
}