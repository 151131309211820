@import './variables';
@import './mixins';

.popup-overlay {
    background: rgba(0, 0, 0, 0.7);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.popup-content {
    margin: auto;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @include dark-common {
        background: $neutrals8;
        color: $neutrals1;
    }

    @keyframes anvil {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }

        1% {
            transform: scale(0.96) translateY(10px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }

        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        }
    }
}

.popup-arrow {
    color: $neutrals1;

    @include dark-common {
        color: $neutrals8;
    }
}

[role='tooltip'].popup-content {
    width: auto;
    background-color: #000;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}