@import "../../../styles/mixins.scss";

.watermark_container {
    pointer-events: none;
}

.watermark_lt {
    position: fixed;
    top:10px;
    left:10px;
    z-index: 10;
}

.watermark_rb {
    position: fixed;
    bottom:10px;
    right:10px;
    z-index: 10;
}