@import "../../../../../styles/mixins.scss";
.section {
    position: relative;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:45px 0 103px;

    @include breakpoint(d) {
        padding: 90px 54px 90px 56px;
    }

    @include breakpoint(m) {
        padding: 45px 27px 45px 28px;
    }
}

.title_box {
    @include inter-bold;
    font-size: 36px;
    color: #14284E;

    @include breakpoint(d) {
        line-height: 120%;
        text-align: center;
        font-size: 64px;
    }

    @include breakpoint(m) {
        font-size: 32px;
    }
}

.subtitle_box {
    @include poppins-regular;
    font-size: 20px;
    color: #14284E;
    margin-top: 15px;

    @include breakpoint(d) {
        font-size: 24px;
        margin-top: 30px;
    }

    @include breakpoint(m) {
        font-size: 12px;
        margin-top: 15px;
    }
}

.content_box {
    margin-top : 65px;

    display: flex;
    justify-content: center;  
    align-items: center;
    flex-direction: row;

    @include breakpoint(d) {
        width: 100%;
        margin-top: 62px;
        flex-direction: column;
    }
}

.card {
    flex: 0 0 273px;
    min-height: 440px;

    @include breakpoint(d) {
        flex: 0 0 auto;
        width: 100%;
    }
}