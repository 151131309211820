@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    overflow: hidden;

    min-height: 798px;
    
    @include breakpoint(d) {
        min-height: 1276px;
        justify-content: flex-start;
    }

    @include breakpoint(t) {
        min-height: 638px;
    }
}

.background_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    z-index: 0;

    min-height: 798px;
    
    @include breakpoint(d) {
        min-height: 1276px;
    }

    @include breakpoint(t) {
        min-height: 638px;
    }

    img {
        width: 100%;
        object-fit: cover;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.splat_box {
    position: absolute;
    top: 151px;
    left: 50%;
    transform: translateX(-60%);
    width: 901px;
    height: 651px;
    z-index: 1;

    // transition: all 0.1s ease-in-out;

    @include breakpoint(d) {
        top: 354px;
        width: 1070px;
        height: 850px;
    }

    @include breakpoint(t) {
        top: 177px;
        width: 535px;
        height: 425px;
    }
}

.logo_box {
    position: absolute;
    top: 204px;
    left: 50%;
    transform: translateX(-50%);
    width: 478px;
    height: 253px;
    z-index: 2;

    // transition: all 0.1s ease-in-out;
    @include breakpoint(d) {
        top: 254px;
        width: 602px;
        height: 318px;
    }

    @include breakpoint(t) {
        top: 127px;
        width: 301px;
        height: 159px;
    }
}

.content_box {
    margin: 489px 0 0;
    @include poppins-medium;
    font-size: 20px;
    color: #14284E;
    line-height: 101.5%;
    z-index: 3;
    text-align: center;

    @include breakpoint(d) {
        margin: 626px 0 0;
        font-size: 30px;
    }

    @include breakpoint(t) {
        margin: 313px 0 0;
        font-size: 15px;
    }
}

.button_box {
    margin: 32px 0 0;
    display: flex;
    justify-content: center;
    z-index: 3;

    @include breakpoint(d) {
        margin: 52px 0 0;
    }
}

.button {
    min-height: 57px;
    min-width: 173px;
    border-radius: 90px;    
    background: #14284E;
    @include poppins-semibold;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #FFF;
    }

    &.disabled {
        background: #AFAFAF;
        cursor: not-allowed;

        &:hover {
            scale: 1.0;
        }
    }

    img {
        width:45px;
        height:45px;
        margin-left: 3px;
    }

    @include breakpoint(d) {
        min-height: 84px;
        min-width: 290px;
        font-size: 40px;

        &:hover {
            scale: 1.0;
        }

        img {
            width: 50px;
            height: 50px;
            margin-left: 8px;
        }
    }

    @include breakpoint(t) {
        min-height: 42px;
        min-width: 145px;
        font-size: 20px;

        img {
            width: 25px;
            height: 25px;
            margin-left: 2px;
        }
    }
}
