@import "./variables.scss";

// @include background-image(logo, png, 100px 100px, center, no-repeat);
@mixin background-image($file, $type, $size, $position, $repeat, $retina: false) {
    background-image: url($image-path + $file + "." + $type);
    background-position: $position;
    background-repeat: $repeat;
    background-size: $size;

    @if ($retina ==true) {
        @include hidpi(1.3) {
            background-image: url($image-path + $file + $image-suffix + "." + $type);
        }
    }
}

@mixin border($style, $radius, $width, $color) {
    border: $width $style $color;
    border-radius: $radius;
}

// @include breakpoint(sm) {}
@mixin breakpoint($value) {
    @if $value ==w {
        @media only screen and (max-width: $w) {
            @content;
        }
    }

    @if $value ==x {
        @media only screen and (max-width: $x) {
            @content;
        }
    }

    @if $value ==d {
        @media only screen and (max-width: $d) {
            @content;
        }
    }

    @if $value ==t {
        @media only screen and (max-width: $t) {
            @content;
        }
    }

    @if $value ==m {
        @media only screen and (max-width: $m) {
            @content;
        }
    }

    @if $value ==a {
        @media only screen and (max-width: $a) {
            @content;
        }
    }

    @if $value ==s {
        @media only screen and (max-width: $s) {
            @content;
        }
    }
}

@mixin breakpointMaxPx($value) {
    @media only screen and (max-width: $value + "px") {
        @content;
    }
}

// @include button-reset;
@mixin button-reset {
    background: transparent;
    border: 0;
    cursor: pointer;
    font-family: $font-family-semibold;
    padding: 0;
    margin: 0;
    user-select: none;
    transition: all 0.2s ease;
    -webkit-appearance: none;
}

// @include column(2);
@mixin column($value) {
    width: calc(100% / (#{$grid-columns} / #{$value}));
}

// @include column-offset(2);
@mixin column-offset($value) {
    margin-left: calc(100% / (#{$grid-columns} / #{$value}));
}

// @include flex(flex, row, wrap, flex-start, flex-start);
@mixin flex($display, $flex-direction, $flex-wrap, $justify-content, $align-items, $align-content: null) {
    @if $display =="inline-flex" {
        display: inline-flex;
    }

    @else {
        display: flex;
    }

    flex-flow: $flex-direction $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
    align-content: $align-content;
}

// @include hidpi(1.3)
@mixin hidpi($ratio: 1.3) {

    @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
    only screen and (min--moz-device-pixel-ratio: $ratio),
    only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
    only screen and (min-resolution: round($ratio * 96dpi)),
    only screen and (min-resolution: $ratio * 1dppx) {
        @content;
    }
}

// @include link-color(#fff, #999, none, underline);
@mixin link-color($value1, $value2, $value3: false, $value4: false) {

    &:link,
    &:visited {
        color: $value1;
        text-decoration: $value3;
    }

    &:hover,
    &:active {
        color: $value2;
        text-decoration: $value4;
    }
}

// @include list-reset;
@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

// @include theme(dark) {}
@mixin theme($value) {
    @media (prefers-color-scheme: $value) {
        @content;
    }
}

@mixin dark {
    @at-root :global(.dark-mode) & {
        @content;
    }
}

@mixin dark-body {
    @at-root .dark-mode {
        @content;
    }
}

@mixin dark-common {
    @at-root .dark-mode & {
        @content;
    }
}

// @include momentum-scroll;
@mixin momentum-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

// @include placeholder-color(#fff);
@mixin placeholder-color($value) {
    &::-webkit-input-placeholder {
        color: $value;
    }

    &:-moz-placeholder {
        color: $value;
    }

    &::-moz-placeholder {
        color: $value;
    }

    &:-ms-input-placeholder {
        color: $value;
    }
}

// @include position($position: absolute, $top: 10px, $left: 10px);
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
    bottom: $bottom;
    left: $left;
    position: $position;
    right: $right;
    top: $top;
}

// @include responsive-img;
@mixin responsive-img {
    display: block;
    height: auto;
    max-width: 100%;
    min-width: 100%;
}

// @include text-truncate;
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// @include vertical-align;
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// @include reset-vertical-align;
@mixin reset-vertical-align {
    top: auto;
    transform: none;
}

@mixin poppins {
    font-family: "poppins", sans-serif;
}

@mixin poppins-medium {
    font-family: "poppins-medium", sans-serif;
}

@mixin poppins-semibold {
    font-family: "poppins-semi-bold", "poppins", sans-serif;
}

@mixin poppins-bold {
    font-family: "poppins-bold", "poppins", sans-serif;
}

@mixin poppins-extrabold {
    font-family: "poppins-extrabold", "poppins", sans-serif;
}

@mixin poppins-regular {
    font-family: "poppins-regular", "poppins", sans-serif;
}

@mixin inter {
    font-family: "inter", sans-serif;
}

@mixin inter-light {
    font-family: "inter-light", sans-serif;
}

@mixin inter-bold {
    font-family: "inter-bold", "inter", sans-serif;
}

@mixin inter-semibold {
    font-family: "inter-semi-bold", "inter", sans-serif;
}

@mixin title {
    @include poppins-bold;
    color: $brown;
    font-size: 64px;
    line-height: 68px;

    @include breakpoint(d) {
        font-size: 40px;
        line-height: 107%;
    }
}


@mixin dark {
    @at-root :global(.dark-mode) & {
        @content;
    }
}

@mixin dark-body {
    @at-root .dark-mode {
        @content;
    }
}

@mixin dark-common {
    @at-root .dark-mode & {
        @content;
    }
}

@mixin not-focusable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}