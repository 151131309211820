@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title_box {
    padding-top:45px;
    @include inter-bold;
    font-size: 36px;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        padding-top: 90px;
        font-size: 64px;
    }

    @include breakpoint(m) {
        padding-top: 45px;
        font-size: 32px;
    }
}

.subtitle_box {
    margin-top:15px;
    @include inter-light;
    font-size: 20px;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        margin-top: 30px;
        font-size: 24px;
    }

    @include breakpoint(m) {
        margin-top: 15px;
        font-size: 12px;
    }
}

.cards_box {
    margin:48px 0 58.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include breakpoint(d) {
        margin: 130px 54px 146px;
        flex-direction: column;
    }

    @include breakpoint(m) {
        margin: 65px 27px 73px;
    }
}

.card {
    flex: 0 0 528px;
    height: 366.5px;

    &:not(:last-child) {
        margin-right: 64px;
    }

    @include breakpoint(d) {
        flex: 0 0 100%;
        margin-right: 0;

        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 128px;
        }
    }

    @include breakpoint(m) {

        &:not(:last-child) {
            margin-bottom: 64px;
        }
    }
}