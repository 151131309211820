@import "../../../../../styles/mixins.scss";
.section {
    position: relative;
    overflow: hidden;
}

.background_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 0;

    img {
        width: 100%;
        object-fit: cover;
        overflow: visible;

        @include breakpoint(d) {
            transform: translateX(40%);
        }

        @include breakpoint(m) {
            transform: translateX(45%);
        }

        @include breakpoint(a) {
            transform: translateX(60%);
        }

        @include breakpoint(s) {
            transform: translateX(100%);
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include breakpoint(d) {
        align-items: center;
        justify-content: center;
        padding: 0 40px 0 46px;
    }

    @include breakpoint(m) {
        padding: 0 20px 0 23px;
    }
}

.logo_box {
    width: 689px;
    margin: 44px 0 0;
    text-align: right;

    @include breakpoint(d) {
        width: 512px;
        margin: 740px 0 0;
    }

    @include breakpoint(t) {
        width: 256px;
        margin: 370px 0 0;
    }
}

.mobile_bg_box {
    padding: 0;
    margin-bottom: 120px;

    @include breakpoint(d) {
        margin-top:30px;
        padding: 26px 30px 22px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.50);
        width: 100%;
        display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
    }

    @include breakpoint(m) {
        margin-top: 15px;
        padding: 13px 15px 11px;
    }
}

.subtitle_box {
    margin: 0 46px 0 0;
    width:546px;
    @include poppins-medium;
    font-size: 20px;
    color: #FFF;
    text-align: right;

    @include breakpoint(d) {
        width: 100%;
        margin: 0;
        font-size: 24px;
        text-align: center;
    }

    @include breakpoint(m) {
        font-size: 12px;
    }
}

.button_box {
    margin: 30px 46px 22px;
    display: flex;
    justify-content: right;

    @include breakpoint(d) {
        margin: 60px 0 0;
    }

    @include breakpoint(m) {
        margin: 30px 0 0;
    }
}

.button {
    min-width: 258px;
    min-height: 60px;
    border-radius: 69px;
    background: #FFF;
    @include poppins-semibold;
    font-size: 32px;
    color: #14284E;
    transition: all 0.1s ease-in-out;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    img {
        margin-left: 23px;
        width: 45px;
        height: 45px;
    }

    @include breakpoint(d) {
        min-width: 290px;
        min-height: 84px;
        font-size: 40px;

        img {
            margin-left: 10px;
            width: 50px;
            height: 50px;
        }

        &:hover {
            scale: 1;
        }
    }

    @include breakpoint(m) {
        min-width: 145px;
        min-height: 42px;
        font-size: 20px;

        img {
            margin-left: 5px;
            width: 25px;
            height: 25px;
        }
    }
}