@import "../../../../../styles/mixins.scss";
.section {
    position: relative;
    overflow: hidden;
}

.bg_video {
    width: 600px;
    height: 300px;
}

.bg_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .bg_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }

    .bg_img1 {
        position: absolute;
        left: -88px;
        top: -189px;
        width: 478px;
        height: 712px;
        z-index: 1;

        @include breakpoint(d) {
            left: -60px;
            top: 16px;
            width: 342px;
            height: 514px;
        }

        @include breakpoint(m) {
            left: -30px;
            top: 8px;
            width: 171px;
            height: 257px;
        }
    }

    .bg_img2 {
        position: absolute;
        right: -28px;
        top: 136px;
        width: 223px;
        height: 247px;
        z-index: 0;

        @include breakpoint(d) {
            right: -42px;
            top: 64px;
            width: 192px;
            height: 218px;
        }

        @include breakpoint(m) {
            right: -21px;
            top: 32px;
            width: 96px;
            height: 109px;
        }
    }

    .bg_img3 {
        position: absolute;
        right: -188px;
        top: 490px;
        width: 711px;
        height: 781px;
        z-index: 2;

        @include breakpoint(d) {
            right: -198px;
            top: 694px;
            width: 586px;
            height: 626px;
        }

        @include breakpoint(m) {
            right: -99px;
            top: 347px;
            width: 293px;
            height: 313px;
        }
    }

    .bg_img4 {
        position: absolute;
        left: -116px;
        top: 1199px;
        width: 387px;
        height: 604px;
        z-index: 1;

        @include breakpoint(d) {
            left: -94px;
            top: 1138px;
            width: 230px;
            height: 364px;
        }

        @include breakpoint(m) {
            left: -47px;
            top: 569px;
            width: 115px;
            height: 182px;
        }
    }

    .bg_img5 {
        position: absolute;
        right: -70px;
        top: 1429px;
        width: 307px;
        height: 316px;
        z-index: 1;

        @include breakpoint(d) {
            right: -162px;
            top: 1404px;
            width: 264px;
            height: 254px;
        }

        @include breakpoint(m) {
            right: -81px;
            top: 702px;
            width: 132px;
            height: 127px;
        }
    }
}

.container {
    margin-top: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(d) {
        margin-top: 192;
        padding: 0 54px;
    }

    @include breakpoint(m) {
        margin-top: 96px;
        padding: 0 27px;
    }
}

.banner_box {
    position: absolute;
    top: 21px;
    left: 0;
    background-color: #0052FF;

    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.05;
    }

    @include breakpoint(d) {
        top: 26px;
        height: 94px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        top: 13px;
        height: 47px;
    }
}

.banner_slide_box {
    display: flex;
    flex-direction: row;
    padding:22px 0 15px;

    margin-left: 0px;
    animation-duration: 10s;
    animation-name: slide;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes slide {
        from {
            transform: translateX(0px);
        }

        to {
            transform: translateX(-1044px);
        }
    }

    @include breakpoint(d) {
        padding: 0;
        justify-content: center;

        @keyframes slide {
            from {
                transform: translateX(0px);
            }
    
            to {
                transform: translateX(calc(-100% - 49px));
            }
        }
    }
}

.banner_img {
    margin-right: 49px;
    width: 995px;
    height:87px;

    @include breakpoint(d) {        
        height: 100%;
        width: 100%;
    }

}

.connect_box {
    border-radius: 15px;    
    background: rgba( 0, 0, 0, 0.5);
    
    padding:34px 69px 30px 107px;
    text-align: center;
    z-index: 2;

    @include breakpoint(d) {
        padding: 46px 30px 44px;
    }

    @include breakpoint(m) {
        padding: 23px 15px 22px;
    }

}
.title_box {
    @include poppins-bold;
    font-size: 32px;
    color: #FFF;
    margin-bottom: 10px;

    @include breakpoint(d) {
        font-size: 24px;
        margin-bottom: 20px;
    }

    @include breakpoint(m) {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.contents_box {
    @include poppins-bold;
    font-size: 48px;
    color: #FFF;
    margin-bottom: 25px;
    line-height: 101.5%;

    @include breakpoint(d) {
        font-size: 40px;
        margin-bottom: 46px;
    }

    @include breakpoint(m) {
        font-size: 20px;
        margin-bottom: 23px;
    }
}

.exp_box {
    border-radius: 15px;
    border: 2px solid #D3D3D3;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 36px;
    margin-bottom: 32px;

    @include breakpoint(d) {
        padding: 24px 14px 24px 24px;
        margin-bottom: 46px;
    }

    @include breakpoint(m) {
        padding: 12px 7px 12px 12px;
        margin-bottom: 23px;
    }
}

.exp_title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    @include poppins-medium;
    font-size: 24px;
    color:#000;
    text-align: left;

    @include breakpoint(m) {
        font-size: 12px;
    }
}

.exp_icon {
    width:32px;
    height:32px;
    margin:4px 13px 0 0;

    @include breakpoint(d) {
        width:30px;
        height:30px;
        margin:3px 10px 0 0;
    }

    @include breakpoint(m) {
        width:15px;
        height:15px;
        margin:1.5px 5px 0 0;
    }
}

.connect_button_box {
    display: flex;
    justify-content: center;

    @include breakpoint(d) {
        flex-direction: column;
        align-items: center;
    }
}

.connect_button {
    min-width: 239px;
    min-height: 44px;
    border-radius: 90px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    
    button {
        @include poppins-semibold;
        font-size: 24px;
        color: #14284E;
        line-height: 16px;
    }

    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    @include breakpoint(d) {
        min-width: 414px;
        min-height: 72px;
        width: fit-content;
        display: inline-flex;

        button {
            font-size: 40px;
        }

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-width: 207px;
        min-height: 36px;

        button {
            font-size: 20px;
        }
    }
}

.more_button {
    min-width: 185px;
    min-height: 44px;
    border-radius: 90px;    
    background: #FFF;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include poppins-semibold;
    font-size: 24px;
    color: #14284E;
    line-height: 16px;

    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    @include breakpoint(d) {
        min-width: 414px;
        width: fit-content;
        min-height: 72px;
        margin-top: 18px;
        margin-left: 0;

        font-size: 40px;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-width: 207px;
        min-height: 36px;
        margin-top: 9px;

        font-size: 20px;
    }
}

.fruits_select_box {
    margin-top:562px;
    border-radius: 15px;
    padding :52px 26px 44px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-bottom: 101px;
    z-index: 2;

    @include breakpoint(d) {
        margin-top: 96px;
        padding: 38px 28px;
        margin-bottom: 66px;
    }

    @include breakpoint(m) {
        margin-top: 48px;
        padding: 19px 14px;
        margin-bottom: 33px;
    }
}

.select_title_box {
    @include poppins-semibold;
    font-size: 48px;
    color: #FFF;
    margin-bottom: 44px;
    line-height: 101.5%;

    @include breakpoint(d) {
        font-size: 60px;
        margin-bottom: 74px;
    }

    @include breakpoint(m) {
        font-size: 30px;
        margin-bottom: 37px;
    }
}

.select_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 286px;

    @include breakpoint(d) {
        height: auto;
    }
}

.select_card {
    width: 200px;
    height: 270px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    position: relative;
    margin: 0 4px 44px;

    &.selected {
        width: 212px;
        height: 286px;
    }

    &.disabled{
        cursor: not-allowed;
    }

    @include breakpoint(d) {
        width: auto;
        height: auto;
        flex: 1 1 104px;
        aspect-ratio: 52/70;

        &.selected {
            width: auto;
            height: auto;
            flex: 1 1 110px;
            aspect-ratio: 52/70;
        }
    }

    @include breakpoint(m) {
        flex: 1 1 52px;
        aspect-ratio: 52/70;

        &.selected {
            flex: 1 1 57px;
            aspect-ratio: 52/70;
        }
    }
}

.select_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: scale, opacity 0.1s ease-in-out;
    border-radius: 15px;
    border: 4px solid transparent;
    background-origin: border-box;
    background-image: linear-gradient(0, #CFCFCF, #CFCFCF);

}

.select_img_normal {
    opacity: 1.0;
    background-image: linear-gradient(0, #CFCFCF, #CFCFCF);

    &.hidden {
        opacity: 0.0;
    }

    &.selected{
        background-image: linear-gradient(120deg, #FF6464, #F8FF73);
        animation: rotateGradient 1s linear infinite;
    }

    @keyframes rotateGradient {
        0% {
            background-image: linear-gradient(120deg, #FF6464, #F8FF73);
        }

        5% {
            background-image: linear-gradient(138deg, #FF6464, #F8FF73);
        }

        10% {
            background-image: linear-gradient(156deg, #FF6464, #F8FF73);
        }

        15% {
            background-image: linear-gradient(174deg, #FF6464, #F8FF73);
        }

        20% {
            background-image: linear-gradient(192deg, #FF6464, #F8FF73);
        }

        25% {
            background-image: linear-gradient(210deg, #FF6464, #F8FF73);
        }

        30% {
            background-image: linear-gradient(228deg, #FF6464, #F8FF73);
        }

        35% {
            background-image: linear-gradient(246deg, #FF6464, #F8FF73);
        }

        40% {
            background-image: linear-gradient(264deg, #FF6464, #F8FF73);
        }

        45% {
            background-image: linear-gradient(282deg, #FF6464, #F8FF73);
        }

        50% {
            background-image: linear-gradient(300deg, #FF6464, #F8FF73);
        }

        55% {
            background-image: linear-gradient(318deg, #FF6464, #F8FF73);
        }

        60% {
            background-image: linear-gradient(336deg, #FF6464, #F8FF73);
        }

        65% {
            background-image: linear-gradient(354deg, #FF6464, #F8FF73);
        }

        70% {
            background-image: linear-gradient(372deg, #FF6464, #F8FF73);
        }

        75% {
            background-image: linear-gradient(390deg, #FF6464, #F8FF73);
        }

        80% {
            background-image: linear-gradient(408deg, #FF6464, #F8FF73);
        }

        85% {
            background-image: linear-gradient(426deg, #FF6464, #F8FF73);
        }

        90% {
            background-image: linear-gradient(444deg, #FF6464, #F8FF73);
        }

        95% {
            background-image: linear-gradient(462deg, #FF6464, #F8FF73);
        }

        100% {
            background-image: linear-gradient(480deg, #FF6464, #F8FF73);
        }
    }
}

.mint_button {
    min-width: 214px;
    min-height: 52px;
    border-radius: 90px;
    color:#14284E;
    background: #FFF;
    @include poppins-semibold;
    font-size: 24px;
    line-height: 16px;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    &.disabled {
        background: #AFAFAF;
        cursor: not-allowed;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(d) {
        min-width: 288px;
        min-height: 68px;
        font-size: 40px;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-width: 144px;
        min-height: 34px;
        font-size: 20px;
    }
}

.loading {
    width: 32px;
    height: 32px;
}

.modal_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.modal_outer {
    padding: 0 !important;
    overflow: hidden;
}

.modal_bg_video {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal_title_box {
    @include poppins-bold;
    font-size: 48px;
    color: #14284E;
    margin: 29px 0 0;
    
    opacity: 0;
    animation: fadeIn 0.5s 3.0s forwards;

    @include breakpoint(m) {
        font-size: 24px;
        margin: 14.5px 0 0;
    }
}

.nft_image_box {
    margin-top: 43px;
    width:314px;
    height: 314px;
    border-radius: 15px;
    overflow: hidden;

    opacity: 0;
    animation: fadeIn 0.5s 3.5s forwards;

    @include breakpoint(m) {
        width: 157px;
        height: 157px;
        margin-top: 21.5px;
    }
}

.nft_info_box {
    margin-top: 15px;
    @include poppins-medium;
    font-size: 20px;
    color: #14284E;

    opacity: 0;
    animation: fadeIn 0.5s 3.25s forwards;

    @include breakpoint(m) {
        font-size: 10px;
        margin-top: 7.5px;
    }
}

.close_button_box {
    opacity: 0;
    animation: fadeIn 0.5s 3.75s forwards;
    margin:30px 0 40px;
    display: flex;
    justify-content: center;
    flex-direction: row;

    @include breakpoint(d) {
        flex-direction: column-reverse;
    }

    @include breakpoint(m) {
        margin: 15px 0 20px;
    }
}

.detail_button,
.close_button {
    transition: all 0.1s ease-in-out;
    border-radius: 90px;
    background: #14284E;
    @include poppins-semibold;
    font-size: 24px;
    line-height: 16px;
    min-height: 52px;
    min-width: 181px;
    color: #FFF;

    &:hover {
        scale: 1.1;
        color: #FFF;
    }

    @include breakpoint(d) {
        min-width: 183px;
        min-height: 52px;
        font-size: 20px;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-width: 91px;
        min-height: 26px;
        font-size: 12px;
    }
}

.detail_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;

    @include breakpoint(d) {
        margin-left: 0;
        margin-bottom: 28px;
    }

    @include breakpoint(m) {
        margin-bottom: 14px;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}