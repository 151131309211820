@import "../../../../styles/mixins.scss";

.main {
    position: relative;

    border-top: 1px solid rgba(25, 29, 49, 0.12);
    overflow: hidden;
}

.background {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2310px;
    height: 1779px;
    z-index: -1;
    transform: translateX(-50%) translateY(-50%);

    @include breakpoint(d) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateX(0) translateY(0);
    }
}

.pc_img {
    display: block;

    @include breakpoint(d) {
        display: none;
    }
}

.mobile_img {
    position: absolute;
    display: none;

    @include breakpoint(d) {
        display: block;
    }

    &.mobile_img_1 {
        left: 0;
        top: 0;
    }

    &.mobile_img_2 {
        left: 0;
        bottom: 0;
    }

    &.mobile_img_3 {
        top: 25%;
        right: 0;
    }
}

.container {
    padding: 83px 0 136px;
    text-align: center;
    min-height: 1109px;

    @include breakpoint(d) {
        padding: 65px 0 69px;
    }
}

.title {
    @include poppins-bold;
    font-size: 64px;
    line-height: 96px;
    color: #11265B;

    @include breakpoint(d) {
        margin: 0 38px;
        font-size: 40px;
        line-height: normal;
    }

}

.content {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;

    color: #11265B;

    @include breakpoint(d) {
        margin: 19px 38px 0;

        font-size: 15px;
        line-height: normal;
    }
}

.exchange_empty_tap_container {
    margin: 48px auto 0;
    width: 743px;
    min-height: 86px;
    background: rgba(117, 70, 219, 0.26);
    border-radius: 25px 25px 0 0;
    @include breakpoint(d) {
        width: auto;
        margin: 38px 35px 0;
    }
}

.exchange_tap_container {
    margin: 48px auto 0;
    width: 743px;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .exchange_tap {
        flex: 1 0 auto;
        font-size: 32px;
        padding: 19px 0;
        @include poppins-semibold;
        color: #CBCBCB;
        background: rgba(26, 0, 82, 0.26);
        cursor: pointer;

        &.selected{
            color: #fff;
            background: rgba(117, 70, 219, 0.26);
        }

        &.disabled {
            cursor: not-allowed;
        }

        @include breakpoint(d) {
            font-size: 13px;
            padding: 7px 0;
        }

        &:first-child {
            border-radius: 25px 0 0 0;
        }

        &:last-child {
            border-radius: 0 25px 0 0;
        }
    }

    @include breakpoint(d) {
        width: auto;
        margin: 38px 35px 0;
    }
}

.exchange_info_container {
    background: rgba(117, 70, 219, 0.26);
    border-radius: 0 0 25px 25px;
    padding: 57px 72px 32px;
    width: 743px;
    margin: 0 auto;

    @include breakpoint(d) {
        width: auto;
        padding: 29px 22px 44px;
        margin: 0 35px;
    }
}

.bridge_container {
    margin-top: 60px;

    @include breakpoint(d) {
        margin-top: 37px;
    }

    .bridge_btn {
        border-radius: 11px;
        background: #14284E;
        padding: 11px 68px 12px 69px;
        color: #fff;
        @include poppins-medium;
        font-size: 32px;

        @include breakpoint(d) {
            padding: 11px 34px 12px 35px;
            font-size: 13px;
        }
    }
}

.exp {
    text-align: left;
    width: 693px;
    margin: 48px auto 0;
    @include poppins;
    font-style: normal;
    font-size: 11px;
    line-height: 16px;

    color: #B4B4B4;

    @include breakpoint(d) {
        width: auto;
        margin: 33px 63px 0;
        font-size: 10px;
        line-height: normal;
        text-align: center;
    }

}

.confirm_modal {
    border-radius: 20px !important;
    padding: 32px !important;

    max-width: 448px !important;

    @include breakpoint(d) {
        padding: 29px 23px 36px !important;
    }
}

.modal_title {
    text-align: left;

    @include poppins-semibold;
    font-size: 40px;
    line-height: 40px;
    /* identical to box height, or 125% */

    /* Default/White */

    color: #23262F;

    @include breakpoint(d) {
        font-size: 32px;
        line-height: normal;
    }
}

.modal_content_box {
    margin-top: 32px;

    @include breakpoint(d) {
        margin-top: 37px;
    }
}

.modal_contents {
    font-size: 16px;
    line-height: 24px;
    /* or 133% */
    color: #23262F;
    white-space: pre-line;

    @include breakpoint(d) {
        font-size: 10px;
        line-height: normal;
    }
}

.modal_btn_container {

    margin-top: 16px;

    
    @include breakpoint(d) {
        margin-top: 37px;
    }

    .modal_btn {
        width: 100%;
        background: #12265B;
        padding: 16px 24px;
        border-radius: 90px;

        color: #FFFFFF;

        transition: all 0.2s ease-in-out;

        &:hover {
            background: #32467B;
        }

        &.error {
            background: #FEE500;
            color: #23262F;

            &:hover {
                background: #FEF530;
            }
        }

        @include breakpoint(d) {
            font-size: 16px;
            padding: 6px 0;
        }
    }
}