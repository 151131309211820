@import "../../../../styles/mixins.scss";

.outer_modal {
    background: #DBCFF6 !important;
    border-radius: 20px;
    padding: 23px 32px 28px !important;

    width: 398px !important;
    max-height: 100%;

    @include breakpoint(d) {
        width: 100%;
        padding: 29px 23px 46px !important;
    }
}

.modal_container {
    .modal_header {
        @include poppins-semibold;
        font-size: 32px;
        line-height: 40px;
        /* 125% */
        letter-spacing: -0.32px;
        /* identical to box height, or 100% */

        color: #11265B;

        @include breakpoint(d) {
            font-size: 32px;
            line-height: normal;
            padding-right: 50px;
        }
    }

    .modal_body {
        margin-top: 44px;
        padding-right: 16px;
        overflow-y: auto;
        max-height: 383px;

        &::-webkit-scrollbar-thumb {
            background-color: #969696;
        }

        &::-webkit-scrollbar-track {
            background-color: #FAFAFA;
            border-radius: 10px;
        }

        @include breakpoint(d) {
            margin-top: 11px;
            max-height: 383px;
            padding-right: 7px;

            overflow-y: scroll;
        }
    }

    .wallet_item {
        padding: 9px 14px 10px 13px;
        background: rgba(18, 38, 91, 0.8);
        border-radius: 20px;

        @include breakpoint(d) {
            padding: 9px 11px;
        }

        &:not(:first-child) {
            margin-top: 12px;

            @include breakpoint(d) {
                margin-top: 14px;
            }
        }

        &.disabled {
            background: rgba(85, 101, 143, 0.8);

            .wallet_connect {
                button {
                    background: #B3B3B3;
                    pointer-events: none;
                }
            }
        }

        &.connected {
            .wallet_connect {
                button {
                    background: #FC6868;
                }
            }
        }
    }

    .wallet_inner_row {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(d) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 10px;
        }
    }

    .wallet_row {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-top: 9px;
            justify-content: flex-end;
        }
    }

    .wallet_name {
        @include poppins-semibold;
        font-size: 18px;
        line-height: normal;
        color: #FFFFFF;
        margin-left: 9px;

        @include breakpoint(d) {
            font-size: 15px;
            margin-left: 0;

        }
    }

    .wallet_address {
        font-size: 12px;
        line-height: normal;
        text-align: end;
        /* identical to box height */
        color: #FFFFFF;

        @include breakpoint(d) {
            font-size: 8px;
        }
    }

    .wallet_icon {
        width: 30px;
        height: 30px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .wallet_connect {
        button {
            padding: 3px 0;

            @include poppins-medium;

            text-align: center;
            width: 158px;

            background: #3CCAA8;
            border-radius: 50px;
            color: #FFFFFF;
            font-size: 15px;
            line-height: normal;


            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }

            @include breakpoint(d) {
                width: 100%;
                padding: 5px 0;
                font-size: 12px;
                border-radius: 20px;
            }
        }

        @include breakpoint(d) {
            width: 100%;
        }

        &.loading {
            button {
                pointer-events: none;
                cursor: initial;
                background-color: rgba(60, 202, 168, 0.5);
            }
        }
    }

    .modal_footer {
        flex: 0 0 115px;
        margin-top: 28px;

        @include breakpoint(d) {
            margin: 24px 14px 0;
        }

        .modal_footer_text {
            font-size: 12px;
            line-height: normal;
            text-align: center;

            color: #11265B;

            @include breakpoint(d) {
                font-size: 8px;
            }

        }

        .modal_footer_button {
            margin-top: 38px;
            text-align: center;

            @include breakpoint(d) {
                margin-top: 36px;
            }

            a {
                display: inline-block;
                padding: 7px 30px;
                text-align: center;

                @include poppins-medium;
                font-size: 18px;
                line-height: normal;
                /* identical to box height */

                color: #FFFFFF;

                background: #5458AD;
                border-radius: 50px;

                transition: all 0.3s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }

                @include breakpoint(d) {
                    padding: 6px 27px;
                    font-size: 16px;
                }
            }
        }
    }
}


.outer_confirm_modal {
    width: 448px !important;
    padding: 32px !important;

    background: #FCFCFD !important;
    /* depth/4 */

    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 20px;

    @include breakpoint(d) {
        width: 100%;
        padding: 29px 23px 38px !important;
    }
}

.confirm_modal_container {

    .confirm_modal_header {
        @include poppins-semibold;
        font-size: 32px;
        line-height: 40px;
        /* identical to box height, or 125% */


        /* Neutrals/2 */

        color: #23262F;

        @include breakpoint(d) {
            font-size: 32px;
            line-height: normal;
            padding-right: 50px;
        }
    }

    .confirm_modal_body {
        margin-top: 32px;
        font-size: 16px;
        line-height: 24px;
        /* Neutrals/2 */

        color: #23262F;

        @include breakpoint(d) {
            margin-top: 17px;
            font-size: 10px;
            line-height: normal;
        }
    }

    .confirm_modal_qr {
        margin: 10px 0;
        text-align: center;
        margin: 26px 0 0;

        .confirm_modal_body {
            text-align: left;

            @include breakpoint(d) {
                font-size: 8px;
                text-align: center;
            }
        }

        .confirm_modal_qr_body {
            margin: 22px 33px 0;
            font-size: 8px;
            line-height: normal;
            text-align: center;

            color: #11265B;

        }
    }

    .confirm_modal_footer {
        margin-top: 32px;

        .confirm_modal_btn {
            @include poppins-medium;

            width: 100%;
            padding: 16px 0;

            background: #12265B;
            border-radius: 90px;
            color: #FFFFFF;

            &.error {
                background: #FEE500;
                color: #11265B;
            }

            transition: all 0.3s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }

            @include breakpoint(d) {
                font-size: 16px;
                padding: 6px 0;
            }
        }
    }
}