@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    overflow: hidden;
    background-color: #14284E;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title_box {
    padding-top: 45px;
    @include inter-bold;
    font-size: 36px;
    color: #FFF;
    text-align: center;

    @include breakpoint(d) {
        padding-top: 90px;
        font-size: 64px;
    }

    @include breakpoint(m) {
        padding-top: 45px;
        font-size: 32px;
    }
}

.subtitle_box {
    margin-top: 15px;
    @include inter-light;
    font-size: 20px;
    color: #FFF;
    text-align: center;

    @include breakpoint(d) {
        margin-top: 30px;
        font-size: 24px;
    }

    @include breakpoint(m) {
        margin-top: 15px;
        font-size: 12px;
    }
}

.nft_box {
    margin: 51px 0 38px;
    height:373px;
    padding-top:46px;

    @include breakpoint(d) {
        margin: 100px 0 104px;
        height: 746px;
        padding-top: 92px;
    }

    @include breakpoint(m) {
        margin: 50px 0 52px;
        height: 373px;
        padding-top: 46px;
    }
}

.nft_card {
    width:236px !important;
    height: 282px;
    border-radius: 15px;
    border: 2px #E9E9E9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding:19px 22px 70px;
    transition: all 0.1s ease-in-out;

    @include breakpoint(d) {
        width: 472px !important;
        height: 564px;
        padding: 38px 44px 140px;
    }

    @include breakpoint(m) {
        width: 236px !important;
        height: 282px;
        padding:19px 22px 70px;
    }
}

.nft {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.contents_box {
    margin-bottom: 38px;
    @include poppins-regular;
    font-size: 20px;
    color: #FFF;
    text-align: center;

    @include breakpoint(d) {
        margin-bottom: 122px;
        font-size: 24px;
        padding: 0 106px;
    }

    @include breakpoint(m) {
        margin-bottom: 61px;
        font-size: 12px;
        padding: 0 53px;
    }
}

.button_box {
    display: flex;
    justify-content: center;
    flex-direction: row;

    @include breakpoint(d) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.button {
    min-width: 188px;
    min-height: 44px;
    border-radius: 90px;
    margin-bottom: 45px;
    background-color: #FFF;
    color:#14284E;
    transition: all 0.1s ease-in-out;
    @include poppins-semibold;
    font-size: 24px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
        margin-right: 56px;
    }

    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    img {
        width: 158px;
        height: 27.48px;
    }

    @include breakpoint(d) {
        min-width: 416px;
        min-height: 88px;
        font-size: 40px;
        margin-bottom: 110px;
        margin-right: 0;

        &:not(:last-child) {
            margin-bottom: 30px;
            margin-right: 0;
        }

        &:hover {
            scale: 1;
        }

        img {
            width: 316px;
            height: 55.36px;
        }
    }

    @include breakpoint(m) {
        min-width: 208px;
        min-height: 44px;
        font-size: 20px;
        margin-bottom: 55px;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        img {
            width: 158px;
            height: 27.48px;
        }
    }
}