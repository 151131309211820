@import '../../../styles/mixins.scss';

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    background: rgba($neutrals1, .9);
    opacity: 0;
    animation: showModal .4s forwards;
    overflow: auto;
    z-index: 999;
    overflow: hidden;

    @include breakpoint(d) {
        padding: 38px;
    }

    @include dark {
        background: rgba($neutrals2, .9);
    }


    @keyframes showModal {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


    .outer {
        position: relative;
        width: 100%;
        max-width: 603px;
        margin: auto;
        padding: 29px 68px 50px;
        background: #fff;
        border-radius: 20px;
        z-index: 2;

        .container {
            .close {
                position: absolute;
                top: 32px;
                right: 32px;
                width: 40px;
                height: 40px;
                font-size: 0;
                transition: transform .2s ease-in-out;

                &:hover {
                    transform: rotate(90deg);
                }

                @include breakpoint(d) {
                    top: 29px;
                    right: 23px;
                }
            }
        }

        @include breakpoint(d) {
            padding: 29px 23px 33px;
        }
    }
}