@import '../../../styles/mixins.scss';

.user {
    position: relative;
    z-index: 4;

    @include breakpoint(m) {
        position: static;
    }

    .login_box {
        display: flex;

        .login_button {
            display: flex;
            padding: 12px 16px;
            gap: 12px;

            height: 40px;

            background: #ffffff;
            border-radius: 90px;
            transition: box-shadow 0.3s ease-in-out;

            &:hover {
                box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.7);
            }

            span {
                @include poppins-semibold;
                width: 100%;
                justify-content: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                /* identical to box height, or 114% */

                text-align: center;

                /* Font */

                color: #14284E;
            }

            @include breakpoint(d) {
                padding: 12px 36px;

                span {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            @include breakpoint(m) {
                padding: 12px 18px;

                span {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }

    .outer {

        @include breakpoint(d) {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.67);
            backdrop-filter: blur(2px);
        }
    }

    .body {
        position: absolute;
        top: calc(100% + 20px);
        left: 50%;
        width: 256px;
        margin-left: -128px;
        padding: 32px 16px 20px;
        box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4);
        border-radius: 12px;
        background: $neutrals8;
        opacity: 0;
        transform: scale(.9);
        transform-origin: 50% 50px;
        will-change: transform;
        animation: showUser .4s forwards;
        z-index: 3;

        @include breakpoint(x) {
            left: auto;
            right: 0;
        }

        @include breakpoint(d) {
            position: fixed;
            top: auto;
            left: calc(50% - 128px);
            top: calc(50% - 300px);
            margin: 0;
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 100%;
            width: 32px;
            height: 13px;
            transform: translateX(-50%);
            background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto;

            @include breakpoint(x) {
                left: 80%;
            }

            @include breakpoint(d) {
                display: none;
            }
        }

        @include dark {
            background: $neutrals2;

            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
            }
        }

        @keyframes showUser {
            0% {
                opacity: 0;
                transform: scale(.9);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }

        .name {
            color: $indigo;
            overflow: hidden;
            font-size: 24px;

            font-weight: 600;
            line-height: 32px;
            text-overflow: ellipsis;
        }

        .code {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .number {
                font-weight: 500;
                color: $neutrals4;
            }

            .copy {
                margin-left: 8px;

                svg {
                    fill: #B8B8B8;
                    transition: fill .2s;
                }

                &:hover {
                    svg {
                        fill: $indigo;
                    }
                }
            }
        }

        .wrap {
            margin-bottom: 7px;
            padding: 8px;
            border-radius: 16px;

            .token_container {
                border-top: 2px solid #C7C7C7;

                .chain_container {
                    .chain_box {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 7px;
                        }

                        font-size: 14px;
                        @include poppins-semibold;
                        color: #777E90;
                    }

                    .view_more {
                        box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.20);
                        width: 100%;
                        padding: 8px;
                        font-size: 18px;
                        color: #777E90;
                        text-align: center;
                        border-radius: 16px;
                        @include poppins-semibold;
                        background: #FCFCFD;
                        margin-top: 8px;
                    }

                    .line {
                        box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.20);
                        padding: 8px;
                        border-radius: 16px;
                        background: #FCFCFD;
                        margin-top: 8px;


                        .info {
                            color: #777E90;
                            font-size: 12px;
                            line-height: 20px;
                        }

                        .details {
                            display: flex;
                            align-items: center;

                            .preview {
                                flex-shrink: 0;
                                width: 30px;
                                height: 30px;

                                img {
                                    width: 30px;
                                    height: 30px;
                                }
                            }

                            .price_container {
                                margin-left: 9px;
                            }

                            .name {
                                color: #777E90;
                                @include poppins-semibold;
                                font-size: 10px;
                                line-height: 10px;
                            }

                            .price {
                                @include poppins-semibold;
                                color: #777E90;
                                font-size: 18px;
                                margin-top: 3px;
                                line-height: 18px;
                            }

                            .loading {
                                margin-top: 3px;
                                width: 18px;
                                height: 18px;
                                font-size: 18px;
                                // 7546D, 26%
                                color: rgba(117, 84, 214, 0.26);
                            }
                        }
                    }

                    .button {
                        width: 100%;
                        height: 32px;
                        padding: 0 10px;
                        font-size: 14px;
                        border-radius: 20px;
                        font-size: 14px;
                        color: $neutrals2;
                        background: none;
                        box-shadow: 0 0 0 2px $neutrals6 inset;
                        justify-content: center;

                        @include dark {
                            color: $neutrals7;
                        }

                        &:hover {
                            background: $blue;
                            box-shadow: 0 0 0 2px $blue inset;
                            color: $neutrals8;

                            svg {
                                fill: $neutrals8;
                            }
                        }
                    }
                }
            }

        }

        .saparator {
            height: 1px;
            background: #E6E8EC;
        }

        .menu {

            .item {
                display: flex;
                align-items: center;
                padding: 17px 0 12px;
                color: $neutrals4;

                &:not(:last-child) {
                    border-bottom: 1px solid $neutrals6;

                    @include dark {
                        border-color: $neutrals3;
                    }
                }

                &[href] {
                    &:hover {
                        color: #8A043F;

                        svg {
                            fill: #8A043F;
                        }

                        path {
                            &:first-child {
                                fill: #8A043F;
                            }
                        }

                        .text {
                            color: #575E70;
                        }

                        .red_text {
                            color: #8A043F;
                        }
                    }
                }

                .icon {
                    flex-shrink: 0;
                    width: 20px;
                    margin-right: 8px;

                    svg {
                        fill: #FA047F;
                        transition: fill .2s;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .text {
                    color: #777E90;
                    margin-right: auto;
                    transition: color .2s;
                }

                .red_text {
                    color: #FA047F;
                    margin-right: auto;
                    transition: color .2s;
                }
            }
        }

        .close_btn_container {
            display: none;
            text-align: center;
            margin-top: 19px;

            @include breakpoint(d) {
                display: block;
            }

            .close_btn {
                @include poppins-medium;
                font-size: 20px;
                padding: 3px 52px;
                background-color: #B3B3B3;
                border-radius: 20px;
                height: 36px;
                color: #FFF;
            }
        }
    }
}