@import "../../../../styles/mixins.scss";

.main {
    position: relative;

    border-top: 1px solid rgba(25, 29, 49, 0.12);
    overflow: hidden;
}

.background {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2310px;
    height: 2050px;
    z-index: -1;
    transform: translateX(-52%) translateY(-57%);

    @include breakpoint(d) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateX(0) translateY(0);
    }
}

.pc_img {
    display: block;

    @include breakpoint(d) {
        display: none;
    }
}

.mobile_img {
    position: absolute;
    display: none;

    @include breakpoint(d) {
        display: block;
    }

    &.mobile_img_1 {
        left: 0;
        top: 0;
    }

    &.mobile_img_2 {
        top: 15%;
        right: 0;
    }

    &.mobile_img_3 {
        left: 0;
        bottom: 0;
    }

}

.container {
    padding-top: 83px;
    text-align: center;
    min-height: 1735px;

    @include breakpoint(d) {
        padding-top: 65px;
        padding-bottom: 83px;
    }
}

.title {
    @include poppins-bold;
    font-size: 64px;
    line-height: 96px;
    color: #11265B;

    @include breakpoint(d) {
        font-size: 40px;
        line-height: normal;
    }

}

.content {
    margin-top: 10px;
    font-size: 15px;
    line-height: 22px;

    color: #11265B;

    @include breakpoint(d) {
        margin-top: 13px;

        font-size: 15px;
        line-height: normal;
    }
}

.staking_control_container {
    display: flex;
    margin-top: 52px;
    justify-content: center;

    @include breakpoint(d) {
        margin-top: 47px;

        flex-direction: column;
        align-items: center;
        padding: 0 38px;
    }
}

.staking_control_box {
    flex: 0 0 507px;
    background: rgba(117, 70, 219, 0.26);
    border-radius: 20px;
    padding: 27px 25px 23px;

    @include breakpoint(d) {
        flex: 1 0 auto;
        width: 100%;
        margin-bottom: 41px;
    }

    &:last-child {
        margin-left: 50px;

        @include breakpoint(d) {
            margin-left: 0;
            margin-bottom: 0;
        }
    }

    .input_box {
        background: rgba(17, 38, 91, 0.85);
        border-radius: 20px;
        padding: 13px 28px 16px 34px;

        @include breakpoint(d) {
            position: relative;
            padding: 11px 19px 14px 21px;
        }

        .balance_row {
            display: flex;
            justify-content: end;

            @include breakpoint(d) {
                position: absolute;
                right: 19px;
                top: 10px;
            }

            .balance {
                margin-right: 9px;
                font-size: 15px;
                line-height: 22px;
                /* identical to box height */

                color: #04FAB0;

                @include breakpoint(d) {
                    margin-right: 6px;
                    font-size: 10px;
                    line-height: normal;
                }
            }

            .max_button {
                background: #000E34;
                border-radius: 98px;
                width: 61px;

                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                text-align: center;

                color: #FFFFFF;

                transition: all 0.1s ease-in-out;

                &:hover {
                    background: #202E34;
                }

                @include breakpoint(d) {
                    width: 50px;
                    font-size: 10px;
                    line-height: normal;
                }
            }
        }

        .input_row {
            margin-top: 23px;
            display: flex;

            @include breakpoint(d) {
                margin-top: 0;
                flex-wrap: wrap;

            }

            .icon {
                flex: 0 0 auto;

                img {
                    width: 47px;
                    height: 45px;
                    object-fit: cover;
                }

                @include breakpoint(d) {
                    text-align: left;
                    flex: 1 0 100%;
                }
            }

            .name {
                @include poppins-semibold;
                flex: 0 0 auto;
                padding-left: 22px;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height */

                color: #FFFFFF;

                @include breakpoint(d) {
                    flex: 0 0 auto;
                    padding-left: 0;
                    font-size: 20px;
                    margin-top: 9px;
                }

            }

            .input {
                @include poppins-semibold;
                padding-left: 22px;

                width: 255px;
                background: none;
                font-size: 24px;
                line-height: 36px;
                /* identical to box height */

                text-align: right;

                color: #FFFFFF;

                &::placeholder {
                    color: #AAA;
                }

                @include breakpoint(d) {
                    width: 110px;
                    padding-left: 0;
                    font-size: 20px;
                    margin-top: 9px;
                }
            }
        }
    }


    .info_box {
        margin-top: 18px;
        padding: 0 15px;

        @include breakpoint(d) {
            margin-top: 23px;
            padding: 0;
        }

        .info_row {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: 15px;

                @include breakpoint(d) {
                    margin-top: 6px;
                }
            }

            .label {
                flex: 1 0 auto;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;

                color: #11265B;

                @include breakpoint(d) {
                    font-size: 10px;
                    line-height: normal;
                }
            }

            .icon {
                flex: 0 0 auto;
                width: 26px;
                height: 26px;
                object-fit: cover;

                @include breakpoint(d) {
                    width: 20px;
                    height: 20px;
                }
            }

            .icon_base {
                flex: 0 0 auto;
                width: 22px;
                height: 29px;
                object-fit: cover;
            
                @include breakpoint(d) {
                    width: 17.6px;
                    height: 23.2px;
                }
            }

            .value {
                flex: 0 0 auto;
                padding-left: 10px;
                font-size: 16px;
                line-height: 24px;

                color: #11265B;

                @include breakpoint(d) {
                    padding-left: 4px;
                    font-size: 10px;
                    line-height: normal;
                }
            }
        }
    }

    .control_box {
        margin-top: 21px;

        @include breakpoint(d) {
            margin: 22px 48px 0;
        }

        .stake_button {
            @include poppins-medium;
            width: 100%;
            height: 79px;

            background: #5458AD;
            border-radius: 50px;

            font-size: 24px;
            line-height: 36px;
            /* identical to box height */

            text-align: center;

            color: #FFFFFF;

            transition: all 0.1s ease-in-out;

            &:hover {
                background: #6F73B8;
            }

            &.disabled {
                background: rgba(84, 88, 173, 0.5);
                cursor: default;
                pointer-events: none;
            }

            @include breakpoint(d) {
                height: 36px;
                font-size: 20px;
                line-height: normal;
                /* identical to box height */
            }
        }
    }
}

.staking_info_container {
    width: 1064px;

    margin: 52px auto 0;
    background: rgba(117, 70, 219, 0.26);
    border-radius: 20px;

    padding: 20px 26px 63px 25px;

    @include breakpoint(d) {
        width: auto;
        margin: 41px 38px 0;
        padding: 15px 22px 29px 21px;
    }

    .info_title {
        @include poppins-semibold;
        padding-left: 14px;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */

        color: #11265B;
        text-align: left;
    }

    .info_exp {
        @include poppins-medium;
        padding-left: 14px;

        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-align: left;
        color: #11265B;

        @include breakpoint(d) {
            font-size: 10px;
            line-height: normal;
        }
    }

    .staking_info_row {
        margin-top: 30px;
        display: flex;

        @include breakpoint(d) {
            margin-top: 33px;
            display: block;
        }
    }

    .staking_info_upper_box {
        flex: 0 1 484px;
        padding: 30px 62px;
        background: rgba(17, 38, 91, 0.85);
        border-radius: 20px;

        @include breakpoint(d) {
            flex: 1 0 auto;
            padding: 15px 19px 14px 15px;
        }

        &:last-child {
            margin-left: 40px;

            @include breakpoint(d) {
                margin-left: 0;
                margin-top: 11px;
            }
        }

        .staking_info_box_title {
            display: flex;
            justify-content: left;
            align-items: center;

            .icon {
                width: 26px;
                height: 26px;
                object-fit: cover;

                @include breakpoint(d) {
                    width: 13.5px;
                    height: 13.5px;
                }
            }

            .name {
                font-size: 20px;
                line-height: 24px;

                color: #FFFFFF;
                margin-left: 14px;

                @include breakpoint(d) {
                    font-size: 10px;
                    line-height: normal;
                    margin-left: 4px;
                }
            }

            .question_icon {
                margin-left: 14px;

                @include breakpoint(d) {
                    margin-left: 4px;
                }
            }
        }

        .staking_info_box_value {
            margin-top: 44px;
            text-align: right;

            @include breakpoint(d) {
                margin-top: 32px;
            }

            .value {
                @include poppins-semibold;
                font-size: 32px;
                line-height: 39px;

                color: #FFFFFF;

                @include breakpoint(d) {
                    font-size: 20px;
                    line-height: normal;
                }
            }

            .apy_value {
                @include poppins-semibold;

                font-size: 48px;
                line-height: 58px;
                text-align: right;

                color: #FFFFFF;

                @include breakpoint(d) {
                    font-size: 20px;
                    line-height: normal;
                }
            }
        }

    }

    .staking_info_lower_box {
        margin-top: 32px;
        padding: 29px 59px;
        background: rgba(17, 38, 91, 0.85);
        border-radius: 20px;

        @include breakpoint(d) {
            margin-top: 11px;
            padding: 15px 19px 18px 15px;
        }

        .staking_info_box_title {
            display: flex;
            justify-content: left;
            align-items: center;

            .icon {
                width: 42px;
                height: 26px;
                object-fit: cover;

                @include breakpoint(d) {
                    width: 21px;
                    height: 13px;
                }
            }

            .name {
                margin-left: 7px;
                font-size: 20px;
                line-height: 24px;

                color: #FFFFFF;

                @include breakpoint(d) {
                    margin-left: 4px;
                    font-size: 10px;
                    line-height: normal;
                }
            }

            .question_icon {
                margin-left: 14px;

                @include breakpoint(d) {
                    margin-left: 4px;
                }
            }
        }

        .staking_info_box_value_mobile,
        .staking_info_box_value {
            display: flex;
            justify-content: right;
            align-items: center;
            margin-top: -15px;

            .minor_value {
                @include poppins-semibold;
                font-size: 20px;
                line-height: 30px;
                /* identical to box height */

                color: #FFFFFF;

                @include breakpoint(d) {
                    font-size: 12px;
                    line-height: normal;
                    flex: 0 1 auto;
                    text-align: right;
                }
            }

            .symbol {
                @include poppins-semibold;
                margin: 0 16px;
                font-size: 20px;
                line-height: 30px;
                /* identical to box height */

                color: #FFFFFF;

                @include breakpoint(d) {
                    margin: 0 12px;
                    font-size: 14px;
                    line-height: normal;
                }
            }

            .value {
                @include poppins-semibold;

                font-size: 32px;
                line-height: 48px;
                /* identical to box height */

                color: #FFFFFF;

                @include breakpoint(d) {
                    font-size: 17px;
                    line-height: normal;
                    text-align: right;

                }
            }

            .change_value {
                @include poppins-semibold;
                font-size: 32px;
                line-height: 39px;
                /* identical to box height */

                color: #FA047F;

                &:not(:last-child) {
                    margin: 0 10px 0 17px;

                    @include breakpoint(d) {
                        font-size: 15px;
                        margin: 0 6px 0 0;
                        flex: 0 0 0;
                        text-align: right;
                    }
                }

                @include breakpoint(d) {

                    font-size: 20px;
                    line-height: normal;
                }
            }

            .change_value_pc {
                @include breakpoint(d) {
                    display: none;
                }
            }

            .down_value {
                color: #04FAB0;

            }
        }


        .staking_info_box_value {
            @include breakpoint(d) {
                margin-top: 0px;
            }
        }

        .staking_info_box_value_mobile {
            display: none;
            margin-top: 8px;
            text-align: right;

            @include breakpoint(d) {
                display: block;
            }
        }

        .staking_info_saparator {
            border: 1px solid #677DB7;
            margin: 11px 0 10px;
        }

        .staking_info_box_title_row {
            display: flex;
            justify-content: space-between;

            .staking_info_box_title {
                .icon {
                    width: 26px;
                    height: 26px;
                    object-fit: cover;

                    @include breakpoint(d) {
                        width: 13.5px;
                        height: 13.5px;
                    }
                }
            }

            @include breakpoint(d) {
                display: block;
            }
        }

        .staking_info_box_value_col {
            display: block;


            @include breakpoint(d) {
                &:not(:first-child) {
                    margin-top: 15px;
                }
            }

            .staking_info_box_value_amount {
                @include poppins-semibold;
                display: block;
                margin-top: 16px;
                text-align: right;
                color: #FFFFFF;
                font-size: 32px;

                @include breakpoint(d) {
                    margin-top: 6px;
                    font-size: 20px;
                }
            }

            .align_left {
                text-align: left;

                @include breakpoint(d) {
                    text-align: right;
                }
            }
        }
    }
}


.exp_title {
    @include poppins-semibold;
    margin: 93px 0 0 265px;
    text-align: left;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #11265B;

    @include breakpoint(d) {
        margin: 50px 0 0;
        font-size: 15px;
        line-height: normal;
        text-align: center;
    }
}

.exp {
    margin: 18px 0 0 280px;
    text-align: left;
    font-size: 15px;
    line-height: 22px;
    width: 895px;

    color: #11265B;

    @include breakpoint(d) {
        margin: 17px 38px 0;
        font-size: 6px;
        line-height: normal;
        width: auto;
    }

}

.check_modal {
    background: #DBCFF6 !important;
}

.check_title {
    @include poppins-semibold;
    font-size: 40px;
    line-height: 40px;
    /* identical to box height, or 100% */

    letter-spacing: -0.01em;

    color: #11265B;
    margin-bottom: 44px;

    @include breakpoint(d) {
        font-size: 32px;
        line-height: normal;
        margin-bottom: 46px;
    }
}

.check_token_info_container {
    background: rgba(18, 38, 91, 0.8);
    border-radius: 20px;
    margin-top: 12px;
    padding: 19px 25px 20px;

    @include breakpoint(d) {
        padding: 18px 26px 22px 22px;
    }

    .check_token_info_title {
        @include poppins-semibold;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height */

        color: #FFFFFF;

        @include breakpoint(d) {
            font-size: 20px;
            line-height: normal;
        }
    }

    .mobile_row {
        display: flex;
        flex: 1 0 auto;

        @include breakpoint(d) {
            display: block;
            margin-left: 35px;
        }
    }

    .row {
        margin-top: 17px;
        margin-left: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .col {
        flex: 0 0 auto;
    }

    .icon {
        width: 40px;
        height: 40px;
        object-fit: cover;
    }

    .symbol {
        @include poppins-medium;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
        padding: 0 25px;

        color: #FFFFFF;

        @include breakpoint(d) {
            font-size: 15px;
            line-height: normal;
            text-align: left;
            padding: 0;
        }
    }

    .amount {
        @include poppins-medium;

        flex: 1 0 auto;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height */
        text-align: right;

        color: #FFFFFF;

        @include breakpoint(d) {
            font-size: 15px;
            line-height: normal;
            text-align: left;
        }
    }
}

.check_token_more_info_container {
    margin: 0 18px 0 20px;

    @include breakpoint(d) {
        margin: 34px 0;
    }

    .check_token_more_info_row {
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(d) {
            margin: 9px;
        }
    }

    .check_token_more_info_label {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;

        color: #11265B;

        @include breakpoint(d) {
            font-size: 10px;
            line-height: normal;
        }
    }

    .check_token_more_info_value {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: right;

        color: #11265B;

        @include breakpoint(d) {
            font-size: 10px;
            line-height: normal;
        }

        .icon_base {
            width: 22px;
            height: 29px;
            object-fit: cover;
            margin-right: 10px;

            @include breakpoint(d) {
                width: 15px;
                height: 15px;
                margin-right: 7px;

            }
        }
    }
}

.check_exchange_button_container {
    margin: 32px auto 0;
    width: 308px;
    height: 59px;

    @include breakpoint(d) {
        padding: 0 11px;
        width: 100%;
        height: 36px;
    }
}

.check_exchange_button {
    width: 100%;
    height: 100%;
    background: #5458AD;
    border-radius: 50px;

    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #FFFFFF;

    &.disabled {
        background: rgba(84, 88, 173, 0.5);
        cursor: default;
        pointer-events: none;
    }

    @include breakpoint(d) {
        font-size: 16px;
        line-height: normal;
    }

    .loading {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin: 0 auto;
    }
}

.tooltip {
    max-width: 208px;
    background: #FFFFFF !important;
    border-radius: 15px;
}

.tooltip_text {
    font-size: 10px;
    line-height: 15px;

    color: #11265B;
}

.confirm_modal {
    border-radius: 20px !important;
    padding: 32px !important;

    max-width: 448px !important;

    @include breakpoint(d) {
        padding: 29px 23px 38px !important;
    }
}

.modal_title {
    text-align: left;

    @include poppins-semibold;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    /* Default/White */

    color: #23262F;

}

.modal_content_box {
    margin-top: 32px;
}

.modal_contents {
    font-size: 16px;
    line-height: 24px;
    /* or 133% */
    color: #23262F;
    white-space: pre-line;

    @include breakpoint(d) {
        font-size: 10px;
        line-height: normal;
    }
}

.modal_btn_container {

    margin-top: 16px;

    @include breakpoint(d) {
        margin-top: 37px;
    }

    .modal_btn {
        @include poppins-medium;
        width: 100%;
        background: #12265B;
        padding: 16px 24px;
        border-radius: 90px;

        color: #FFFFFF;

        transition: all 0.2s ease-in-out;

        &:hover {
            background: #32467B;
        }

        &.error {
            background: #FEE500;
            color: #23262F;

            &:hover {
                background: #FEF530;
            }
        }

        @include breakpoint(d) {
            font-size: 16px;
            padding: 6px 0;

            line-height: normal;
        }
    }
}