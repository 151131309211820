@import "../../../styles/mixins.scss";
.section {
    position: relative;
    display: flex;
    justify-content: center;
}

.conatiner {
    margin: 24px 10px;
    height: 1px;
    background-color: #E2E2E2;
}