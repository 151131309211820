@import './variables';
@import './mixins';

body {
    min-width: 360px;
    background: #FFF;
    @include poppins;
    font-size: 14px;
    line-height: 1.7;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $brown;
}

a {
    text-decoration: none;
}

svg,
img {
    vertical-align: middle;
}

.desktop {
    &-hide {
        @include breakpoint(d) {
            display: none !important;
        }
    }

    &-show {
        display: none !important;

        @include breakpoint(d) {
            display: block !important;
        }
    }

    &-text-right {
        @include breakpoint(d) {
            text-align: right;
        }
    }
}

.tablet {
    &-hide {
        @include breakpoint(t) {
            display: none !important;
        }
    }

    &-show {
        display: none !important;

        @include breakpoint(t) {
            display: block !important;
        }
    }
}

.mobile {
    &-hide {
        @include breakpoint(m) {
            display: none !important;
        }
    }

    &-show {
        display: none !important;

        @include breakpoint(w) {
            display: block !important;
        }
    }
}

.Toastify__toast--error {
    width: fit-content;
}

.container {
    width: 100%;
    max-width: 1440px;
    padding: 0;

    @include breakpoint(w) {
        max-width: 1120px;
    }

    @include breakpoint(d) {
        max-width: 100%;
    }
}

nav,
footer,
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pc {
    display: block !important;
    @include breakpoint(d) {
        display: none !important;
    }
}

.mobile {
    display: none !important;
    @include breakpoint(d) {
        display: block !important;
    }
}