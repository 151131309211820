@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    background-image: url("../../../../../assets/images/homepage/juicy_nft_bg.png");
    background-size: cover;
    background-position: center;
}

.container {
    display: flex;
    flex-direction: row;
    padding: 32px 0 14px 0;

    @include breakpoint(d) {
        padding: 64px 54px 120px 56px;
        flex-direction: column;
        align-items: center;
    }

    @include breakpoint(m) {
        padding: 32px 27px 60px 28px;
    }
}

.character_box {
    width:375px;
    height: 553px;

    @include breakpoint(d) {
        width: 476px;
        height: 802px;
    }

    @include breakpoint(m) {
        width: 238px;
        height: 351px;
    }
}

.contents_box {
    margin:18px 0 0 203px;

    @include breakpoint(d) {
        margin: 0;
    }
}

.title_box {
    margin-top: 112px;
    @include inter-bold;
    font-size: 36px;
    color: #FFF;

    @include breakpoint(d) {
        font-size: 64px;
        margin-top: 28px;
        text-align: center;
    }

    @include breakpoint(m) {
        font-size: 32px;
        margin-top: 14px;
    }
}

.subtitle_box {
    margin-top:15px;
    @include inter-light;
    font-size: 20px;
    color: #FFF;

    @include breakpoint(d) {
        text-align: center;
        margin-top: 46px;
        font-size: 28px;
    }

    @include breakpoint(m) {
        margin-top: 23px;
        font-size: 14px;
    }
}

.button_box {
    margin-top: 71px;
    display: flex;
    justify-content: center;
    flex-direction: row;

    @include breakpoint(d) {
        margin-top: 86px;
        flex-direction: column;
        align-items: center;
    }

    @include breakpoint(m) {
        margin-top: 43px;
    }
}

.button {
    min-height: 44px;
    min-width: 188px;
    border-radius: 90px;    
    background: #FFF;
    @include poppins-semibold;
    font-size: 24px;
    display: flex;
    line-height: 16px;
    justify-content: center;
    align-items: center;

    color: #14284E;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    &.disabled {
        background: #AFAFAF;
        cursor: not-allowed;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(d) {
        min-height: 88px;
        min-width: 416px;
        font-size: 40px;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-height: 44px;
        min-width: 208px;
        font-size: 20px;
    }
}

.opensea_button {
    width:208px;
    height: 44px;
    margin-left: 57px;

    transition: all 0.1s ease-in-out;
    &:hover {
        scale: 1.1;
        color: #14284E;
    }

    &.disabled {
        cursor: not-allowed;

        img {
            filter: grayscale(100%) brightness(70%);
        }

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(d) {
        width: 416px;
        height: 88px;
        margin-left: 0;
        margin-top : 30px;

        &:hover {
            scale: 1.0;
        }

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    @include breakpoint(m) {
        width: 208px;
        height: 44px;
        margin-top: 15px;
    }
}