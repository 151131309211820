@import "../../../styles/mixins.scss";

footer {
    background: #14284E;

    @include breakpoint(d) {
        padding: 0 66px;
    }

    @include breakpoint(m) {
        padding: 0 33px;
    }
}

.upper_container {
    padding-top:56px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include breakpoint(d) {
        padding-top: 60px;
        flex-direction: column;
    }

    @include breakpoint(m) {
        padding-top: 30px;
    }
}

.left_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(d) {
        width: 100%;
    }
}

.logo {
    width:209px;
    height: 57px;

    @include breakpoint(d) {
        width: 306px;
        height: 82px;
    }

    @include breakpoint(m) {
        width: 153px;
        height: 41px;
    }
}

.link_container {
    margin-top: 39px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include breakpoint(d) {
        margin-top:76px;
    }

    @include breakpoint(m) {
        margin-top: 38px;
    }
}

.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 46px;

    @include breakpoint(d) {
        margin-left: 20px;
    }

    @include breakpoint(m) {
        margin-left: 10px;
    }
}

.link_item {
    @include poppins-medium;
    font-size: 16px;
    color: #FFF;

    a {
        color: #FFF;

        &:hover {
            color: #ddd;
        }
    }

    &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        background: #FFF;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 4px;
    }

    &:not(:last-child) {
        padding-bottom: 10px;

        @include breakpoint(d) {
            padding-bottom: 20px;
        }
    }

    @include breakpoint(d) {
        font-size: 24px;
    }

    @include breakpoint(m) {
        font-size: 12px;
    }
}

.right_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;

    @include breakpoint(d) {
        width: 100%;
        margin-top: 178px;
        align-items: center;
        justify-content: center;
    }

    @include breakpoint(m) {
        margin-top: 89px;
    }
}

.social_container {
    display: flex;
    flex-direction: row;
}

.social_button {
    width:45px;
    height: 45px;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    @include breakpoint(d) {
        width: 90px;
        height: 90px;

        &:hover {
            scale: 1;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    @include breakpoint(m) {
        width: 45px;
        height: 45px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.copyright {
    font-size: 13px;
    line-height: 16px;
    padding: 79px 0 32px;
    color: #FFF;
    text-align: center;

    @include breakpoint(d) {
        padding: 62px 0 86px;
        font-size: 20px;
        line-height: auto;
    }

    @include breakpoint(m) {
        padding: 31px 0 43px;
        font-size: 10px;
    }
}