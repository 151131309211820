@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding :21px 0 34px;

    @include breakpoint(d) {
        padding: 94px 54px 110px;
        flex-direction: column;
    }

    @include breakpoint(m) {
        padding: 47px 27px 55px;
    }
}

.left_box {
    flex: 0 1 540px;
    display: flex;
    flex-direction: row;

    @include breakpoint(d) {
        flex: 0 1 100%;
    }
}


.img_col {
    display: flex;
    flex-direction: column;

    @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
            margin-top: 40px * ($i - 1);
        }
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    @include breakpoint(d) {
        flex: 1 0 0;

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                margin-top: 80px * ($i - 1);
            }
        }
    }

    @include breakpoint(m) {
        &:not(:last-child) {
            margin-right: 13px;
        }

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                margin-top: 40px * ($i - 1);
            }
        }
    }
}

.img {
    transition: all 0.2s ease-in-out;
    width:120px;
    height:120px;
    opacity: 0;
    transform: translateY(30px);
    border-radius: 8px;
    
    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:hover {
        scale: 1.15;
    }

    @include breakpoint(d) {
        width: 100%;
        height: fit-content;
        aspect-ratio: 1/1;
        
        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        margin-bottom: 5px;
    }
}
.right_box {
    flex: 0 1 513px;
    padding: 63px 0 0 67px;
    display: flex;
    flex-direction: column;

    @include breakpoint(d) {
        flex: 0 1 100%;
        padding: 100px 66px 0 84px;
        align-items: center;
    }

    @include breakpoint(m) {
        padding: 50px 33px 0 42px;
    }
}

.logo {
    width:334px;
    height: 109px;
    object-fit: cover;

    @include breakpoint(d) {
        width: 470px;
        height: 154px;
    }

    @include breakpoint(m) {
        width: 235px;
        height: 77px;
    }
}

.contents_box {
    margin-top: 35px;
    @include poppins-regular;
    font-size: 20px;
    color:#000;
    line-height: 120%;

    @include breakpoint(d) {
        margin-top: 66px;
        font-size: 24px;
        text-align: left;
    }

    @include breakpoint(m) {
        margin-top: 33px;
        font-size: 12px;
    }
}
.button_box {
    margin-top:58px;

    @include breakpoint(d) {
        margin-top: 152px;
    }

    @include breakpoint(m) {
        margin-top: 76px;
    }
}
.button {
    display: inline-block;
    @include poppins-semibold;
    font-size: 24px;
    line-height: 16px;
    padding:14px 40px;
    color:#FFF;
    border-radius: 90px;
    opacity: var(--sds-size-stroke-border);

    background: #14284E;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #fff;
    }

    @include breakpoint(d) {
        padding: 28px 80px;
        font-size: 40px;
    }

    @include breakpoint(m) {
        padding: 14px 40px;
        font-size: 20px;
    }
}