@import "../../../../../styles/mixins.scss";
.section {
    position: relative;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0 130px;
    max-width: 100%;

    @include breakpoint(d) {
        padding: 90px 0 168px;
    }

    @include breakpoint(m) {
        padding: 45px 0 84px;
    }
}

.title_box {
    @include inter-bold;
    font-size: 36px;
    color: #14284E;
    margin-bottom: 92px;

    @include breakpoint(d) {
        font-size: 64px;
        margin-bottom: 54px;
    }

    @include breakpoint(m) {
        font-size: 32px;
        margin-bottom: 27px;
    }
}

.content_box {
    height: 600px;
    overflow: visible;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include breakpoint(d) {
        height: 480px;
    }

    @include breakpoint(m) {
        height: 240px;
    }
}