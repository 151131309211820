@import "../../../../../styles/_mixins.scss";
.section {
    position: relative;
    overflow: hidden;
}

.container {
    position: static;
    text-align: center;
}

.video {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include breakpoint(d) {
        width: 1440px;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.dim_box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title_box {
    padding-top: 224px;
    @include poppins-bold;
    font-size: 96px;
    color: #FFF;
    text-align: center;
    line-height: 101.5%;

    @include breakpoint(d) {
        padding-top: 214px;
        font-size: 110px;
    }

    @include breakpoint(m) {
        padding-top: 107px;
        font-size: 55px;
    }
}

.subtitle_box {
    margin-top: 37px;
    @include poppins-medium;
    font-size: 20px;
    color: #FFF;
    text-align: center;
    line-height: 101.5%;

    @include breakpoint(d) {
        margin-top: 74px;
        font-size: 40px;
    }

    @include breakpoint(m) {
        margin-top: 37px;
        font-size: 20px;
    }
}

.button_box {
    margin: 115px 0 152px;
    display: flex;
    justify-content: center;

    @include breakpoint(d) {
        margin: 310px 0 280px;
    }

    @include breakpoint(m) {
        margin: 155px 0 140px;
    }
}

.button {
    min-height: 56px;
    min-width: 346px;
    border-radius: 90px;    
    background: #14284E;
    @include poppins-semibold;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #FFF;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
        color: #FFF;
    }

    &.disabled {
        background: #AFAFAF;
        cursor: not-allowed;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(d) {
        min-height: 112px;
        min-width: 406px;
        font-size: 40px;

        &:hover {
            scale: 1.0;
        }
    }

    @include breakpoint(m) {
        min-height: 56px;
        min-width: 203px;
        font-size: 20px;
    }
}

.mute_container {
    position: absolute;
    left: 27px;
    bottom: 8px;

    background: rgba(0, 0, 0, 0.37);
    border-radius: 50px;

    width: 120px;
    height: 40px;

    padding: 8px 0 8px 13px;

    transition: all 0.2s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    @include breakpoint(d) {
        left: 11px;
        bottom: 22px;

        &:hover {
            scale: 1.0;
        }
    }
}

.mute_button {
    display: flex;
    align-items: center;

    span {
        margin-top: 3px;
        margin-left: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
    }
}