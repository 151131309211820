@import "../../../../../../styles/_mixins.scss";

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.1s ease-in-out;
}

.img_box {
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    @include breakpoint(d) {
        &:hover {
            scale: 1.0;
        }
    }
}

.img{
    aspect-ratio: 528/295;
    object-fit: cover;
    overflow: visible;
}

.title_box {
    margin-top:18px;
    @include poppins-regular;
    font-size: 15px;
    color:#14284E;

    @include breakpoint(d) {
        margin-top: 36px;
        font-size: 24px;
    }

    @include breakpoint(m) {
        margin-top: 18px;
        font-size: 12px;
    }
}

.name_box {
    @include poppins-semibold;
    font-size: 24px;
    color:#14284E;
    margin-top : 6px;

    @include breakpoint(d) {
        font-size: 48px;
        margin-top: 12px;
    }

    @include breakpoint(m) {
        font-size: 24px;
        margin-top: 6px;
    }
}