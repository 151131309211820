@import "../../../../../styles/mixins.scss";
.section {
    position: relative;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 90px;

    @include breakpoint(d) {
        padding-bottom: 166px;
    }

    @include breakpoint(m) {
        padding-bottom: 83px;
    }
}

.title_box {
    padding: 45px 0 63px;
    @include inter-bold;
    font-size: 36px;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        padding: 90px 0 144px;
        font-size: 64px;
    }

    @include breakpoint(m) {
        padding: 45px 0 72px;
        font-size: 32px;
    }
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &:not(:last-child) {
        margin-bottom: 25px;
    }

    @include breakpoint(d) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding : 0 74px;

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    @include breakpoint(m) {
        padding: 0 37px;
    }
}

.partner_col {
    border-radius: 8px;
    background: rgba(248, 248, 248, 0.74);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -4px -4px 4px 0px rgba(255, 255, 255, 0.25);
    transition: all 0.8s ease-in;
    transform: translateX(-100vw);

    &:not(:last-child) {
        margin-right: 34px;
    }

    @include breakpoint(d) {
        transform: translateX(0);
        flex: 0 1 calc(50% - 26px);
        margin-right: 0;
        margin-bottom: 26px;

        &:not(:last-child) {
            margin-right: 0;
        }


        &:nth-child(2n-1) {
            margin-right: 26px;
        }
    }

    @include breakpoint(m) {
        flex: 0 1 calc(50% - 13px);
        margin-bottom: 13px;

        &:nth-child(2n-1) {
            margin-right: 13px;
        }
    }

}