@import "../../../../../styles/mixins.scss";


.exchange_rate {
  background: #11265B;
  border-radius: 50px;
  display: flex;
  padding: 0 85px;
  align-items: center;
  justify-content: space-between;
  height: 53px;

  @include breakpoint(d) {
    padding: 4px 16px 3px 9px;
    height: auto;
  }
}

.exchange_rate_title {
  flex: 0 0 auto;
  @include poppins-semibold;

  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  /* bg 01 */

  color: #FDEFB2;

  @include breakpoint(d) {
    font-size: 10px;
    line-height: normal;
  }
}

.exchange_rate_content {
  flex: 0 0 auto;

  @include poppins-bold;

  font-size: 14px;
  line-height: 21px;
  text-align: center;

  color: #FFFFFF;

  span {
    font-size: 20px;
    line-height: 30px;
  }

  @include breakpoint(d) {
    font-size: 10px;
    line-height: normal;

    span {
      font-size: 10px;
      line-height: normal;
    }
  }
}


.volume {

  @include poppins;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: right;

  color: #FFFFFF;

  margin-bottom: 2px;

  &:nth-child(2) {
    margin-top: 34px;
  }

  @include breakpoint(d) {
    margin-right: 14px;
  }
}

.token_info_container {

  height: 112px;

  background: rgba(84, 88, 173, 0.54);
  border-radius: 20px;
  padding: 9px 35px 17px 24px;

  @include breakpoint(d) {
    height: auto;
    min-height: 164px;
    padding: 17px 14px 13px 15px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include poppins-medium;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #FFFFFF;

    &:nth-child(2) {
      height: 55px;
    }

    @include breakpoint(d) {
      flex-wrap: wrap;
    }
  }

  .from {
    @include poppins-semibold;

    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    color: #FFFFFF;
  }

  .icon {
    width: 55px;
    height: 55px;

    @include breakpoint(d) {
      width: 51px;
      height: 51px;
    }
  }

  .symbol {
    @include poppins-medium;
    flex: 0 0 100px;

    @include breakpoint(d) {
      margin-left: 17px;
      flex: 1 0 auto;
      text-align: left;
    }
  }

  .amount {
    flex: 0 0 210px;

    input {
      width: 210px;
      background: none;
      text-align: center;
      color: #ffffff;
    }

    @include breakpoint(d) {
      flex: 1 0 100%;
      margin-top: 12px;
      text-align: right;

      input {
        width: 100%;
        text-align: right;
      }
    }
  }

  .button {
    flex: 0 0 61px;

    .max_button {
      width: 61px;
      height: 32px;

      @include poppins-medium;
      font-size: 14px;
      line-height: 21px;

      text-align: center;

      color: #FFFFFF;
    }

    @include breakpoint(d) {
      display: none;

      .max_button {
        height: auto;
      }
    }
  }

  .mobile_button {
    display: none;

    @include breakpoint(d) {
      display: block;
    }
  }
}

.change_button_container {
  margin-top: 18px;

  @include breakpoint(d) {
    margin: 14px 0;
  }
}

.change_button {
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.exchange_button_container {
  margin-top: 41px;

  @include breakpoint(d) {
    margin-top: 62px;
  }
}

.exchange_button {
  width: 220px;
  height: 49px;

  @include poppins-medium;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;

  &.disabled {
    background: rgba(17, 38, 91, 0.5);
    cursor: default;
    pointer-events: none;
  }

  @include breakpoint(d) {
    width: auto;
    height: 36px;
    font-size: 20px;
    line-height: normal;
    padding: 0 36px;
  }
}

.check_modal {
  width: 603px;

  background: #FFFFFF;
  border-radius: 20px;
  text-align: center;
}

.check_title {
  @include poppins-semibold;
  text-align: left;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 100% */

  letter-spacing: -0.01em;
  margin-bottom: 44px;

  color: #11265B;

  @include breakpoint(d) {
    font-size: 32px;
  }
}

.check_token_info_container {

  height: 126px;

  background: #5458AD;
  border-radius: 20px;
  padding: 18px 25px 14px 22px;

  @include breakpoint(d) {
    height: auto;
    padding: 18px 26px 19px 25px;
  }

  .mobile_row {
    display: flex;
    flex: 1 0 auto;

    @include breakpoint(d) {
      display: block;
      margin-left: 22px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include poppins-medium;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    color: #FFFFFF;

    &:nth-child(2) {
      height: 55px;
    }
  }

  .from {
    @include poppins-semibold;

    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    margin-bottom: 9px;

    color: #FFFFFF;

    @include breakpoint(d) {
      margin-bottom: 11px;
    }
  }

  .icon {
    width: 55px;
    height: 55px;

    @include breakpoint(d) {
      width: 51px;
      height: 51px;
    }
  }

  .symbol {
    flex: 0 0 100px;

    @include breakpoint(d) {
      font-size: 15px;
      text-align: left;
      line-height: normal;
    }
  }

  .amount {
    flex: 1 0 auto;
    text-align: right;

    @include breakpoint(d) {
      font-size: 15px;
      text-align: left;
      line-height: normal;
    }
  }
}


.check_token_more_info_container {
  margin: 0 18px 0 20px;

  @include breakpoint(d) {
    margin: 34px 0;
  }

  .check_token_more_info_row {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(d) {
      margin: 9px;
    }
  }

  .check_token_more_info_label {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #11265B;

    @include breakpoint(d) {
      font-size: 10px;
      line-height: normal;
    }
  }

  .check_token_more_info_value {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;

    color: #11265B;

    @include breakpoint(d) {
      font-size: 10px;
      line-height: normal;
    }

    .icon {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 10px;

      @include breakpoint(d) {
        width: 15px;
        height: 15px;
        margin-right: 7px;

      }
    }
  }
}

.check_change_button_container {
  margin-top: -12px;
  margin-bottom: -12px;
}

.check_exchange_button_container {
  margin-top: 25px;

  @include breakpoint(d) {
    margin: 53px 11px 0;
  }
}

.check_exchange_button {
  width: 308px;
  height: 59px;
  border-radius: 50px;

  @include poppins-medium;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;

  color: #FFFFFF;


  &.disabled {
    background: rgba(17, 38, 91, 0.5);
    cursor: default;
    pointer-events: none;
  }

  @include breakpoint(d) {
    width: 100%;
    height: 36px;
    font-size: 16px;
    line-height: normal;
  }

  .loading {
    height: 30px;
    width: 30px;
    font-size: 30px;
    margin: 0 auto;
  }
}

.loading_container {
  min-height: 498px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    height: 100px;
    width: 100px;
    font-size: 100px;
    margin: 0 auto;
    color:#fff;
  }
}