@import "../../../../../../styles/_mixins.scss";

.card {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    border: 2px solid #E9E9E9;
    padding: 16px 13px 25px;

    &:not(:last-child) {
        margin-right: 40px;
    }

    @include breakpoint(d) {
        padding: 32px 30px 42px 26px;

        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 54px;
        }
    }

    @include breakpoint(m) {
        padding: 16px 15px 21px 13px;

        &:not(:last-child) {
            margin-bottom: 27px;
        }
    }
}

.image_box {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.title_box {
    margin-top: 18px;
    @include poppins-semibold;
    font-size: 24px;
    line-height: 120%;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        margin-top: 36px;
        font-size: 48px;
    }

    @include breakpoint(m) {
        margin-top: 18px;
        font-size: 24px;
    }
}

.contents_box {
    @include poppins-semibold;
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    color: #000;

    @include breakpoint(d) {
        margin-top: 10px;
        font-size: 28px;
    }

    @include breakpoint(m) {
        margin-top: 5px;
        font-size: 14px;
    }
}
