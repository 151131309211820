@import "../../../../../../styles/_mixins.scss";

.card {
    padding:34px 29px 27px 28px;
    display: flex;
    flex-direction: column;
    transition: all 0.8s ease-out;
    background-color: #E8E9FF;
    border-radius: 15px;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateX(100vw);

    &.reverse_color {
        background-color: #14284E;

        .title_box {
            color: #FFF;
        }

        .contents_box {
            color: #FFF;
        }
    }

    @include breakpoint(d) {
        padding: 64px 66px 58px 56px;
    }

    @include breakpoint(m) {
        padding: 32px 33px 29px 28px;
    }
}

.title_box {
    @include poppins-medium;
    font-size: 24px;
    margin-bottom: 15px;
    color: #14284E;

    @include breakpoint(d) {
        font-size: 48px;
        margin-bottom: 30px;
    }

    @include breakpoint(m) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}

.contents_box {
    @include poppins-regular;
    font-size: 14px;
    margin-bottom: 42px;
    color: #14284E;
    flex-grow: 1;
    
    @include breakpoint(d) {
        font-size: 28px;
        margin-bottom: 0;
    }

    @include breakpoint(m) {
        font-size: 14px;
    }
}

.social_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    @include breakpoint(d) {
        margin-top: 54px;
    }

    @include breakpoint(m) {
        margin-top: 27px;
    }
}

.social {
    width: 45px;
    height: 45px;
    transition: all 0.1s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    @include breakpoint(d) {
        width: 90px;
        height: 90px;

        &:hover {
            scale: 1;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    
    @include breakpoint(m) {
        width: 45px;
        height: 45px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.button_box {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include breakpoint(d) {
        justify-content: center;
        margin-top: 54px;
    }

    @include breakpoint(m) {
        margin-top: 27px;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 90px;
    padding: 7px 15px;
    background-color: #FFF;
    transition: all 0.1s ease-in-out;
    @include poppins-medium;
    font-size: 15px;
    line-height: 16px;
    color:#000;

    &:hover {
        scale: 1.1;
        color: #000;
    }

    &.disabled {
        background-color: #AFAFAF;
        color: #FFF;
        cursor: not-allowed;

        &:hover {
            scale: 1;
        }
    }

    @include breakpoint(d) {
        padding: 14px 30px;
        line-height: 32px;
        font-size: 30px;

        &:hover {
            scale: 1;
        }
    }

    @include breakpoint(m) {
        padding: 7px 15px;
        line-height: 16px;
        font-size: 15px;
    }
}