@import './variables.scss';
@import './mixins.scss';

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider {
    .slick-track,
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before {
        display: table;
        content: '';
    }

    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.nft_slider {
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
        padding: 0 6.5px;
        transition: all .2s ease-in-out;

        &>div {
            display: flex;
            justify-content: center;
        }
    }

    .slick-active {
        scale: 1.16;

        @include breakpoint(d) {
            scale: 1.0;
        }
    }

    .slick-current {
        scale: 1.32;

        @include breakpoint(d) {
            scale: 1.16;
        }
    }
}

.screenshot_slider {
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;

        &>div {
            display: flex;
            justify-content: center;
        }
    }
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide {
    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0;
    transition: all .2s;
}
