@import "../../../../../styles/mixins.scss";

.section {
    position: relative;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title_box {
    padding-top: 45px;
    @include inter-bold;
    font-size: 36px;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        padding-top: 90px;
        font-size: 64px;
    }

    @include breakpoint(m) {
        padding-top: 45px;
        font-size: 32px;
    }
}

.subtitle_box {
    margin-top: 15px;
    @include inter-light;
    font-size: 20px;
    color: #000;
    text-align: center;

    @include breakpoint(d) {
        margin-top: 30px;
        font-size: 24px;
    }

    @include breakpoint(m) {
        margin-top: 15px;
        font-size: 12px;
    }
}

.cards_box {
    margin: 48px 0 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint(d) {
        margin: 100px 46px 84px;
        flex-direction: column;
    }

    @include breakpoint(m) {
        margin: 50px 23px 42px;
    }
}

.card {
    // 1, 2, 3번째 카드
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        flex: 0 1 360px;
        margin-bottom: 30px;
    }

    &:nth-child(1),
    &:nth-child(2) {
        flex: 0 1 360px;
        margin-right: 20px;
    }

    &:nth-child(4) {
        flex: 0 1 624px;
        margin-right: 20px;

    }

    &:nth-child(5) {
        flex: 0 1 476px;
    }

    @include breakpoint(d) {
        flex: 0 0 100%;
        margin-right: 0;

        &:nth-child(n) {
            flex: 0 0 100%;
            margin-right: 0;
        }
    
        &:not(:last-child) {
            margin-bottom: 54px;
        }
    }

    @include breakpoint(m) {
        &:not(:last-child) {
            margin-bottom: 27px;
        }
    }
}

.gram_icon {
    width: 26px;
    height: 26px;
    margin: 0 8px;

    @include breakpoint(d) {
        width: 52px;
        height: 52px;
        margin: 0 16px;
    }

    @include breakpoint(m) {
        width: 26px;
        height: 26px;
        margin: 0 8px;
    }
}