// Font
$font-family-regular: "poppins", sans-serif;
$font-family-semibold: "poppins", sans-serif;
$font-family-bold: "poppins", sans-serif;
$font-size-default: 18px;

// Default Colors
$color-background: "#fff";
$color-text: #000;
$color-text-light: #fff;
$color-link-default: #4d81fc;
$color-link-hover: #f27c80;
$color-form-error: #f73032;

// Social Colors
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-pinterest: #bd081c;
$color-linkedin: #0077b5;
$color-googleplus: #dd4b39;
$color-instagram: #833ab4;

$red: #ff0000;
$white: #ffffff;
$blue: #3772ff;
$blue-light: #4bc9f0;
$purple: #9757d7;
$purple-light: #cdb4db;
$pink: #ef466f;
$green: #45b26b;
$asphalt: #e4d7cf;
$yellow: #ffd166;

$gray1: #454d59;
$gray2: #768193;
$gray3: #b0bac9;
$gray4: #c9cfda;
$gray5: #dfe2e9;

$neutrals1: #141416;
$neutrals2: #23262f;
$neutrals3: #353945;
$neutrals4: #777e90;
$neutrals5: #b1b5c3;
$neutrals6: #e6e8ec;
$neutrals7: #f4f5f6;
$neutrals8: #fcfcfd;

$brown: #9E5400;
$dark-brown: #6B3A02;
$indigo: #11265B;

// Margins
$margin: 20px;
$padding: 20px;

// Image path
$image-path: "../assets/images/";

// Retina image suffix
$image-suffix: "-2x";

// Font path
$font-path: "../assets/fonts";

// Grid
$grid-columns: 24;
$grid-column-gutter: 30px;

// break points
$w: 1440px;
$x: 1339px;
$d: 1179px;
$t: 1023px;
$m: 767px;
$a: 639px;
$s: 474px;

// Chain
$blockchain: "Klaytn";
$currency: "KLAY";