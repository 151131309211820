@import "../../../../../styles/mixins.scss";

.section {
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    border-top: 1px solid #FFF;
}

.container {
    position: static;
    text-align: center;

    @include breakpoint(d) {
        padding: 0 74px; 
    }

    @include breakpoint(m) {
        padding: 0 37px;
    }
}

.video {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @include breakpoint(d) {
        width: 1440px;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.overlay {
    padding: 129px 0 104px;

    @include breakpoint(d) {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.sub_title {
    @include poppins-bold;
    font-size: 100px;
    line-height: 109.5%;
    /* identical to box height */

    text-align: center;

    color: #FFF;

    @include breakpoint(d) {
        margin-top: 108px;
        font-size: 110px;
        line-height: 109.5%;
    }

    @include breakpoint(m) {
        margin-top: 54px;
        font-size: 55px;
    }
}

.contents {
    @include poppins-medium;
    margin-top: 35px;
    font-size: 20px;
    line-height: 117.5%;
    /* or 24px */

    text-align: center;

    color: #FFF;

    @include breakpoint(d) {
        margin-top: 40px;
        font-size: 40px;
    }

    @include breakpoint(m) {
        margin-top: 20px;
        font-size: 20px;
    }
}

.buttons {
    margin-top: 68px;

    @include breakpoint(d) {
        margin: 212px 0 190px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    @include breakpoint(m) {
        margin-top: 106px 0 95px;
    }
}

.button {

    width:238px;
    height: 70px;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    &:hover {
        scale: 1.05;
    }

    &:not(:last-child) {
        margin-right: 16px;
    }

    @include breakpoint(d) {
        display: block;
        margin-right: 0;

        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 32px;
        }
    }

    @include breakpoint(m) {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}

.mute_container {
    position: absolute;
    left: 27px;
    bottom: 8px;

    background: rgba(0, 0, 0, 0.37);
    border-radius: 50px;

    width: 120px;
    height: 40px;

    padding: 8px 0 8px 13px;

    transition: all 0.2s ease-in-out;

    &:hover {
        scale: 1.1;
    }

    @include breakpoint(d) {
        left: 11px;
        bottom: 22px;
    }
}

.mute_button {
    display: flex;
    align-items: center;

    span {
        margin-top: 3px;
        margin-left: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
    }
}