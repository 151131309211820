@import "../../../styles/mixins.scss";

.symbol_container {
    display: block;
    position: relative;
}

.symbol {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}