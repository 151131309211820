@font-face {
    font-family: "inter";
    src: url("#{$font-path}/Inter-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "inter-light";
    src: url("#{$font-path}/Inter-Light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "inter-bold";
    src: url("#{$font-path}/Inter-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "inter-semi-bold";
    src: url("#{$font-path}/Inter-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins";
    src: url("#{$font-path}/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-extrabold";
    src: url("#{$font-path}/Poppins-ExtraBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-bold";
    src: url("#{$font-path}/Poppins-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-semi-bold";
    src: url("#{$font-path}/Poppins-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-medium";
    src: url("#{$font-path}/Poppins-Medium.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "poppins-regular";
    src: url("#{$font-path}/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}